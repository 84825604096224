
import img5 from '../assets/images/avatars/2.png'

export const BookIssueReturnData = [
    {
        Sl: 1,
        Branch:'dddd',
        BookTitle: 'Mathematic',
        Cover: img5,
        Role: '6',
        UserName: '01.Jan.2024',
        DateOfIssue: '31.Dec.2024',
        DateOfExpiry: '312024',
        Fine: '00:05:00',
        Status: '$10',
        Action: 'ff'
    },
    {
        Sl: 2,
        Branch:'dddd',
        BookTitle: 'Mathematic',
        Cover: img5,
        Role: '6',
        UserName: '01.Jan.2024',
        DateOfIssue: '31.Dec.2024',
        DateOfExpiry: '312024',
        Fine: '00:05:00',
        Status: '$10',
        Action: 'ff'
    }

];