import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'


const AdmissionReport = () => {


    const defalutValue = {
        class: '',
        section: '',
        date:''
    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        class: yup.string().required('Class is Requird!'),
        section: yup.string().required('Section is Requird!'),
        date: yup.date().required('Date is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);

    }
    return (
        <>



            <div class="card p-3">
                <div className='TeachersList'>
                    <h5 className="card-header d-flex align-items-center"> Select Ground</h5>
                </div>


                <div className="card">
                    <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                        {({ errors, touched, resetForm }) => {
                            return <Form className="row">
                                <div className="card-body row g-3">

                                    <div className="col-md-6">
                                        <label className="form-label" htmlFor="">Class</label>

                                        <Field component='select' name='class' className={
                                            `form-control
                                            ${errors.class && touched.class ? ' is-invalid' : ''}`
                                        }>
                                            <option >--Select--</option>
                                            <option value={71}>Six</option>
                                            <option value={82}>Seven</option>
                                            <option value={83}>Eight</option>
                                            <option value={94}>Nine</option>
                                        </Field>
                                        <p className='error_message_p'> <ErrorMessage name="class" /></p>
                                    </div>


                                    <div className="col-md-6">
                                        <label className="form-label" htmlFor="">Section</label>

                                        <Field component='select' name='section' className={
                                            `form-control
                                            ${errors.section && touched.section ? ' is-invalid' : ''}`
                                        }>
                                            <option >--Select--</option>
                                            <option value={71}>All Section</option>
                                            <option value={82}>A</option>
                                            <option value={83}>B</option>
                                            <option value={94}>C</option>
                                        </Field>
                                        <p className='error_message_p'> <ErrorMessage name="section" /></p>
                                    </div>

                                    <div className="col-md-6">
                                        <label className="form-label" htmlFor="formValidationName"> Date</label>

                                        <Field type='datetime-local' name='date' placeholder='Date '
                                            className={
                                                `form-control
                                                             ${errors.date && touched.date ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p className='error_message_p'> <ErrorMessage name="date" /></p>
                                    </div>

                                    <div className="col-12 d-flex justify-content-end py-2">
                                        <button type="submit" name="submitButton" className="btn btn-primary">
                                            <span class="ti-xs ti ti-filter me-1"></span>Filter
                                        </button>
                                        <button type="button" className="btn btn-danger ms-2" onClick={resetForm}>
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        }}

                    </Formik>
                </div>
            </div>
        </>
    )
}

export default AdmissionReport
