
import '../../styles/profileStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import { Tab, TabPanel, Tabs, TabsList } from '@mui/base';
import { useRef, useState } from "react";

import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import { BookIssueReturnData } from '../../data/BookIssueReturnData';

const mangeTabActive = {
    color: '#7367f0',
    borderBottom: '2px solid #7367f0',
    // backgroundColor: '#7367f0',
    borderRadius: '0',
}

const BookIssueReturn = () => {

    const [value, setValue] = useState(0);

    const onHandleChange = (event, newValue) => {
        setValue(newValue)
    }


    const fileInputRef = useRef(null);

    const handleFormClick = () => {
        fileInputRef.current.click();
    };

    const defalutValue = {
        bookTitle: '',
        bookCategory: '',
        role: '',
        userName: '',
        dateOfExpiry: '',
      
    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        bookTitle: yup.string().required('Book Title is Requird!'),
        bookCategory: yup.string().required('Book Category is Requird!'),
        role: yup.string().required('role is Requird!'),
        userName: yup.string().required('User Name is Requird!'),
        dateOfExpiry: yup.date().required('Date Of Expiry is Requird!'),
    })
    const handleSubmit = (value) => {
        console.log(value);
    }


    return (
        <>

            <div className="tabsBox">
                <Tabs value={value} onChange={onHandleChange} className="tabs">
                    <div className="left-tabs">
                        <TabsList className='mb-3'>
                            <Tab style={value === 0 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={0}><i className='manageicon ti ti-list' /> Books List</Tab>
                            <Tab style={value === 1 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={1}><i class="manageicon fa-solid fa-edit" /> Book Issue</Tab>
                        </TabsList>
                    </div>
                    <div className="TabPanelDiv">
                        <TabPanel value={0}>
                            <div class="card p-3">
                                <div className="exportFile">
                                    <div className="fileDownload">
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-copy" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-text" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-code-2" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-printer" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                                        </a>

                                    </div>

                                    <div>
                                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                                    </div>
                                </div>

                                <div className="card-body  px-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                                    <TableCell component={'th'} className=''>Branch</TableCell>
                                                    <TableCell component={'th'} className=''>Book Title</TableCell>
                                                    <TableCell component={'th'} className=''>Cover</TableCell>
                                                    <TableCell component={'th'} className=''>Role</TableCell>
                                                    <TableCell component={'th'} className=''>User Name</TableCell>
                                                    <TableCell component={'th'} className=''>Date Of Issue</TableCell>
                                                    <TableCell component={'th'} className=''>Date Of Expiry</TableCell>
                                                    <TableCell component={'th'} className=''>Fine</TableCell>
                                                    <TableCell component={'th'} className=''>Status</TableCell>
                                                    <TableCell component={'th'} className=''>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>

                                                {BookIssueReturnData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                                            <TableCell component={'td'}>{item.Branch}</TableCell>
                                                            <TableCell component={'td'}>{item.BookTitle}</TableCell>
                                                            <TableCell component={'td'}>
                                                                <ul class="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
                                                                    <li
                                                                        data-bs-toggle="tooltip"
                                                                        data-popup="tooltip-custom"
                                                                        data-bs-placement="top"
                                                                        class="avatar avatar-md pull-up"
                                                                        title="Lilian Fuller">
                                                                        <img src={item.Cover} alt="Avatar" class="rounded-circle" />
                                                                    </li>
                                                                </ul>
                                                            </TableCell>
                                                            <TableCell component={'td'}>{item.Role}</TableCell>
                                                            <TableCell component={'td'}>{item.UserName}</TableCell>
                                                            <TableCell component={'td'}>{item.DateOfIssue}</TableCell>
                                                            <TableCell component={'td'}>{item.DateOfExpiry}</TableCell>
                                                            <TableCell component={'td'}>{item.Fine}</TableCell>
                                                            <TableCell component={'td'}><span class="badge bg-label-success me-1">Issued</span></TableCell>
                                                            <TableCell component={'td'}>
                                                                <div className=' d-flex align-items-center'>
                                                                    <button type="button" className="btn rounded-pill btn-outline-dark waves-effect fs-tiny p-1 me-2">
                                                                        <span className="ti ti-menu-2 ti-xs me-1" />Details
                                                                    </button>
                                                                    <button type="button" class="btn rounded-pill btn-icon btn-danger waves-effect waves-light btn-sm">
                                                                        <span class="ti ti-trash ti-xs"></span>
                                                                    </button>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>

                        <TabPanel value={1}>
                            <div className="card p-3">
                                <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                    {({ errors, touched, resetForm }) => {
                                        return <Form className="row">
                                            <div className="card-body">
                                                <div id="formValidationExamples" className="row g-3 w-50 m-auto">

                                                   

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationSelect2">Book Category </label>
                                                        <Field component='select' name='bookCategory' className={
                                                            `form-control
                                                            ${errors.bookCategory && touched.bookCategory ? ' is-invalid' : ''}`
                                                        }>
                                                            <option >--Select--</option>
                                                            <option value={71}>Airtel Prepaid</option>
                                                            <option value={82}>BSNL Prepaid</option>
                                                            <option value={83}>Vi Prepaid</option>
                                                            <option value={94}>JIO</option>
                                                        </Field>
                                                        <p className='error_message_p'> <ErrorMessage name="bookCategory" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationSelect2">Book Title </label>
                                                        <Field component='select' name='bookTitle' className={
                                                            `form-control
                                                            ${errors.bookTitle && touched.bookTitle ? ' is-invalid' : ''}`
                                                        }>
                                                            <option >--Select--</option>
                                                            <option value={71}>Airtel Prepaid</option>
                                                            <option value={82}>BSNL Prepaid</option>
                                                            <option value={83}>Vi Prepaid</option>
                                                            <option value={94}>JIO</option>
                                                        </Field>
                                                        <p className='error_message_p'> <ErrorMessage name="bookTitle" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationSelect2">Role </label>
                                                        <Field component='select' name='role' className={
                                                            `form-control
                                                            ${errors.role && touched.role ? ' is-invalid' : ''}`
                                                        }>
                                                            <option >--Select--</option>
                                                            <option value={71}>Airtel Prepaid</option>
                                                            <option value={82}>BSNL Prepaid</option>
                                                            <option value={83}>Vi Prepaid</option>
                                                            <option value={94}>JIO</option>
                                                        </Field>
                                                        <p className='error_message_p'> <ErrorMessage name="role" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationSelect2">User Name  </label>
                                                        <Field component='select' name='userName' className={
                                                            `form-control
                                                            ${errors.userName && touched.userName ? ' is-invalid' : ''}`
                                                        }>
                                                            <option >--Select--</option>
                                                            <option value={71}>Airtel Prepaid</option>
                                                            <option value={82}>BSNL Prepaid</option>
                                                            <option value={83}>Vi Prepaid</option>
                                                            <option value={94}>JIO</option>
                                                        </Field>
                                                        <p className='error_message_p'> <ErrorMessage name="userName" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationName">Date Of Expiry </label>
                                                        <Field type='datetime-local'
                                                            name='dateOfExpiry' placeholder=''
                                                            className={
                                                                `form-control
                                                                     ${errors.dateOfExpiry && touched.dateOfExpiry ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="dateOfExpiry" /></p>
                                                    </div>


                                                    <div className="col-12 d-flex justify-content-end py-2">
                                                        <button type="button" className="btn btn-danger me-2" onClick={resetForm}>
                                                            <span className="ti-xs ti ti-x me-1"></span>Reset
                                                        </button>

                                                        <button
                                                            type="submit" name="submitButton" className="btn btn-primary">
                                                            <span class="ti-xs ti ti-plus me-1"></span>Save
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    }}
                                </Formik>
                            </div>

                        </TabPanel>
                    </div>
                </Tabs>
            </div>


        </>
    )
}

export default BookIssueReturn
