import Dasboard from "../../componets/dasboardPage/DasboardPage"


function DasboardPage () {
    return (
        <>
        <Dasboard/>
        </>
    )
}

export default DasboardPage