const ReportClassSectionData = [
    {
        Sl: 1,
        Class: 'Six',
        Section: 'A (04)',
        TotalStudents: '04',
    },
    							
    {
        Sl: 2,
        Class: 'Seven',
        Section: 'A (04)',
        TotalStudents: '06',
    },
    {
        Sl: 3,
        Class: 'Eight',
        Section: 'A (04)',
        TotalStudents: '06',
    },
    {
        Sl: 4,
        Class: 'Eight',
        Section: 'B (02)',
        TotalStudents: '06',
    },
    {
        Sl: 5,
        Class: 'Seven',
        Section: 'B (02)',
        TotalStudents: '06',
    },
    {
        Sl: 6,
        Class: 'Six',
        Section: 'B (02)',
        TotalStudents: '06',
    },
    {
        Sl: 7,
        Class: 'Six',
        Section: 'A (0)',
        TotalStudents: '06',
    },
    {
        Sl: 8,
        Class: 'Seven',
        Section: 'A (0)',
        TotalStudents: '07',
    },
    {
        Sl: 9,
        Class: 'Nine',
        Section: 'A (0)',
        TotalStudents: '07',
    }

];

export default ReportClassSectionData;
