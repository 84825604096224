import '../../styles/profileStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import { Tab, TabPanel, Tabs, TabsList } from '@mui/base';
import { useRef, useState } from "react";

import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import { BooksData } from '../../data/BooksData';

const mangeTabActive = {
    color: '#7367f0',
    borderBottom: '2px solid #7367f0',
    // backgroundColor: '#7367f0',
    borderRadius: '0',
}

const Books = () => {

    const [value, setValue] = useState(0);

    const onHandleChange = (event, newValue) => {
        setValue(newValue)
    }


    const fileInputRef = useRef(null);

    const handleFormClick = () => {
        fileInputRef.current.click();
    };

    const defalutValue = {
        bookTitle: '',
        bookISBNNo: '',
        author: '',
        edition: '',
        purchaseDate: '',
        bookCategory:'',
        publisher:'',
        description:'',
        price:'',
        totalStock: '',
    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        bookTitle: yup.string().required('Book Title is Requird!'),
        bookISBNNo: yup.string().required('Book ISBN No is Requird!'),
        author: yup.string().required('Author is Requird!'),
        edition: yup.string().required('Edition is Requird!'),
        purchaseDate: yup.date().required('Purchase Date is Requird!'),
        bookCategory: yup.string().required('Book Category is Requird!'),
        publisher: yup.string().required('Publisher is Requird!'),
        description: yup.string().required('Description is Requird!'),
        price: yup.string().required('Price is Requird!'),
        totalStock: yup.string().required('Total Stock is Requird!'),
    })
    const handleSubmit = (value) => {
        console.log(value);
    }


    return (
        <>

            <div className="tabsBox">
                <Tabs value={value} onChange={onHandleChange} className="tabs">
                    <div className="left-tabs">
                        <TabsList className='mb-3'>
                            <Tab style={value === 0 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={0}><i className='manageicon ti ti-list' /> Books List</Tab>
                            <Tab style={value === 1 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={1}><i class="manageicon fa-solid fa-edit" />Create Book</Tab>
                        </TabsList>
                    </div>
                    <div className="TabPanelDiv">
                        <TabPanel value={0}>
                            <div class="card p-3">
                                <div className="exportFile">
                                    <div className="fileDownload">
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-copy" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-text" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-code-2" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-printer" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                                        </a>

                                    </div>

                                    <div>
                                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                                    </div>
                                </div>

                                <div className="card-body  px-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                                    <TableCell component={'th'} className=''>Book Title</TableCell>
                                                    <TableCell component={'th'} className=''>Cover</TableCell>
                                                    <TableCell component={'th'} className=''>Edition</TableCell>
                                                    <TableCell component={'th'} className=''>ISBN No</TableCell>
                                                    <TableCell component={'th'} className=''>Category</TableCell>
                                                    <TableCell component={'th'} className=''>Description</TableCell>
                                                    <TableCell component={'th'} className=''>Purchase Date</TableCell>
                                                    <TableCell component={'th'} className=''>Price</TableCell>
                                                    <TableCell component={'th'} className=''>Total Stock</TableCell>
                                                    <TableCell component={'th'} className=''>Issued Copies</TableCell>
                                                    <TableCell component={'th'} className=''>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>

                                                {BooksData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                                            <TableCell component={'td'}>{item.BookTitle}</TableCell>
                                                            <TableCell component={'td'}>
                                                                <ul class="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
                                                                    <li
                                                                        data-bs-toggle="tooltip"
                                                                        data-popup="tooltip-custom"
                                                                        data-bs-placement="top"
                                                                        class="avatar avatar-md pull-up"
                                                                        title="Lilian Fuller">
                                                                        <img src={item.Cover} alt="Avatar" class="rounded-circle" />
                                                                    </li>
                                                                </ul>
                                                            </TableCell>
                                                            <TableCell component={'td'}>{item.Edition}</TableCell>
                                                            <TableCell component={'td'}>{item.ISBNNo}</TableCell>
                                                            <TableCell component={'td'}>{item.Category}</TableCell>
                                                            <TableCell component={'td'}>{item.Description}</TableCell>
                                                            <TableCell component={'td'}>{item.PurchaseDate}</TableCell>
                                                            <TableCell component={'td'}>{item.Price}</TableCell>
                                                            <TableCell component={'td'}>{item.TotalStock}</TableCell>
                                                            <TableCell component={'td'}>{item.IssuedCopies}</TableCell>
                                                            <TableCell component={'td'}>
                                                                <div className=' d-flex align-items-center'>
                                                                    <button type="button" class="btn rounded-pill btn-icon btn-outline-dark waves-effect me-1 btn-sm">
                                                                        <span class="fa-solid fa-pen-nib fa-sm"></span>
                                                                    </button>
                                                                    <button type="button" class="btn rounded-pill btn-icon btn-danger waves-effect waves-light btn-sm">
                                                                        <span class="ti ti-trash ti-xs"></span>
                                                                    </button>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>

                        <TabPanel value={1}>
                            <div className="card p-3">
                                <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                    {({ errors, touched, resetForm }) => {
                                        return <Form className="row">
                                            <div className="card-body">
                                                <div id="formValidationExamples" className="row g-3 w-50 m-auto">

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationDob">Book Title</label>
                                                        <Field type='text' name='bookTitle' placeholder='Book Title  '
                                                            className={
                                                                `form-control
                                                             ${errors.bookTitle && touched.bookTitle ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="bookTitle" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationDob">Book ISBN No</label>
                                                        <Field type='text' name='bookISBNNo' placeholder='Book ISBN No '
                                                            className={
                                                                `form-control
                                                             ${errors.bookISBNNo && touched.bookISBNNo ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="bookISBNNo" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationDob">Author</label>
                                                        <Field type='text' name='author' placeholder='Author '
                                                            className={
                                                                `form-control
                                                             ${errors.author && touched.author ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="author" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationDob">Edition</label>
                                                        <Field type='text' name='edition' placeholder='Edition '
                                                            className={
                                                                `form-control
                                                             ${errors.edition && touched.edition ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="edition" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationName">Purchase Date </label>
                                                        <Field type='datetime-local'
                                                            name='purchaseDate' placeholder='Purchase Date  '
                                                            className={
                                                                `form-control
                                                                     ${errors.purchaseDate && touched.purchaseDate ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="purchaseDate" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationSelect2">Book Category </label>
                                                        <Field component='select' name='bookCategory' className={
                                                            `form-control
                                                            ${errors.bookCategory && touched.bookCategory ? ' is-invalid' : ''}`
                                                        }>
                                                            <option >--Select--</option>
                                                            <option value={71}>Airtel Prepaid</option>
                                                            <option value={82}>BSNL Prepaid</option>
                                                            <option value={83}>Vi Prepaid</option>
                                                            <option value={94}>JIO</option>
                                                        </Field>
                                                        <p className='error_message_p'> <ErrorMessage name="bookCategory" /></p>
                                                    </div>



                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationDob">Publisher</label>
                                                        <Field type='text' name='publisher' placeholder='Publisher '
                                                            className={
                                                                `form-control
                                                             ${errors.publisher && touched.publisher ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="publisher" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationDob">Description</label>
                                                        <Field type='text' name='description' placeholder='Description '
                                                            className={
                                                                `form-control
                                                             ${errors.description && touched.description ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="description" /></p>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationDob">Price</label>
                                                        <Field type='text' name='price' placeholder='Price '
                                                            className={
                                                                `form-control
                                                             ${errors.price && touched.price ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="price" /></p>
                                                    </div>



                                                    {/* Profile Picture  */}
                                                    <div className="col-12">
                                                        <div className="card mb-4">
                                                            <h5 className="card-header">Cover Image</h5>
                                                            <div className="card-body p-0">
                                                                <form onClick={handleFormClick} action="" className="dropzone needsclick dz-clickable" id="dropzone-basic">
                                                                    <div className="dz-message needsclick fs-6">
                                                                        Drop files here or click to upload
                                                                        {/* <span className="note needsclick">(This is just a demo dropzone. Selected files are
                                                                    <span className="fw-medium">not</span> actually uploaded.)</span> */}
                                                                    </div>
                                                                </form>
                                                                <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 w-100">
                                                        <label className="form-label" htmlFor="formValidationDob">Total Stock</label>
                                                        <Field type='text' name='totalStock' placeholder='Total Stock '
                                                            className={
                                                                `form-control
                                                             ${errors.totalStock && touched.totalStock ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="totalStock" /></p>
                                                    </div>


                                                    <div className="col-12 d-flex justify-content-end py-2">
                                                        <button type="button" className="btn btn-danger me-2" onClick={resetForm}>
                                                            <span className="ti-xs ti ti-x me-1"></span>Reset
                                                        </button>

                                                        <button
                                                            type="submit" name="submitButton" className="btn btn-primary">
                                                            <span class="ti-xs ti ti-plus me-1"></span>Save
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    }}
                                </Formik>
                            </div>

                        </TabPanel>
                    </div>
                </Tabs>
            </div>


        </>
    )
}

export default Books
