import img1 from '../assets/images/avatars/15.png'
import img2 from '../assets/images/avatars/6.png'
import img3 from '../assets/images/avatars/3.png'
import img4 from '../assets/images/avatars/11.png'
import img5 from '../assets/images/avatars/2.png'

export const EmpAdminData = [
    {
        Sl: 1,
        Photo: img1,
        Branch: 'Mathematic',
        StaffId: '6',
        Name: '01.Jan.2024',
        Designation: '31.Dec.2024',
        Department: '00:05:00',
        Email: '$10',
        MobileNo: 'cdfff',
        Action: 'ff'
    },
    {
        Sl: 2,
        
        Photo: img1,
        Branch: 'Mathematic',
        StaffId: '6',
        Name: '01.Jan.2024',
        Designation: '31.Dec.2024',
        Department: '00:05:00',
        Email: '$10',
        MobileNo: 'cdfff',
        Action: 'ff'
    }

];


export const EmpTeacherData = [
    {
        Sl: 1,
        Photo: img2,
        Branch: 'Mathematic',
        StaffId: '6',
        Name: '01.Jan.2024',
        Designation: '31.Dec.2024',
        Department: '00:05:00',
        Email: '$10',
        MobileNo: 'cdfff',
        Action: 'ff'
    },
    {
        Sl: 2,
        
        Photo: img2,
        Branch: 'Mathematic',
        StaffId: '6',
        Name: '01.Jan.2024',
        Designation: '31.Dec.2024',
        Department: '00:05:00',
        Email: '$10',
        MobileNo: 'cdfff',
        Action: 'ff'
    }

];



export const EmpAccountantData = [
    {
        Sl: 1,
        Photo: img3,
        Branch: 'Mathematic',
        StaffId: '6',
        Name: '01.Jan.2024',
        Designation: '31.Dec.2024',
        Department: '00:05:00',
        Email: '$10',
        MobileNo: 'cdfff',
        Action: 'ff'
    },
    {
        Sl: 2,
        
        Photo: img3,
        Branch: 'Mathematic',
        StaffId: '6',
        Name: '01.Jan.2024',
        Designation: '31.Dec.2024',
        Department: '00:05:00',
        Email: '$10',
        MobileNo: 'cdfff',
        Action: 'ff'
    }

];





export const EmpLibrarianData = [
    {
        Sl: 1,
        Photo: img4,
        Branch: 'Mathematic',
        StaffId: '6',
        Name: '01.Jan.2024',
        Designation: '31.Dec.2024',
        Department: '00:05:00',
        Email: '$10',
        MobileNo: 'cdfff',
        Action: 'ff'
    },
    {
        Sl: 2,
        
        Photo: img4,
        Branch: 'Mathematic',
        StaffId: '6',
        Name: '01.Jan.2024',
        Designation: '31.Dec.2024',
        Department: '00:05:00',
        Email: '$10',
        MobileNo: 'cdfff',
        Action: 'ff'
    }

];



export const EmpReceptionistData = [
    {
        Sl: 1,
        Photo: img5,
        Branch: 'Mathematic',
        StaffId: '6',
        Name: '01.Jan.2024',
        Designation: '31.Dec.2024',
        Department: '00:05:00',
        Email: '$10',
        MobileNo: 'cdfff',
        Action: 'ff'
    },
    {
        Sl: 2,
        
        Photo: img5,
        Branch: 'Mathematic',
        StaffId: '6',
        Name: '01.Jan.2024',
        Designation: '31.Dec.2024',
        Department: '00:05:00',
        Email: '$10',
        MobileNo: 'cdfff',
        Action: 'ff'
    }

];







