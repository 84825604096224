import '../../styles/profileStyle.css'
import { Tab, TabPanel, Tabs, TabsList } from '@mui/base';
import { useRef, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'


const mangeTabActive = {
    color: '#7367f0',
    borderBottom: '2px solid #7367f0',
    // backgroundColor: '#7367f0',
    borderRadius: '0',
}

const ResetPassword = () => {

    const [value, setValue] = useState(0);

    const onHandleChange = (event, newValue) => {
        setValue(newValue)
    }


    const [inputValue, setInputValue] = useState('');

    // Function to handle input change
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const defalutValue = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        Username:''
    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        currentPassword: yup.string().required('Current Password is Requird!'),
        newPassword: yup.string().required('New Password is Requird!'),
        confirmPassword: yup.string().required('Confirm Password is Requird!'),
        Username: yup.string().required('Username is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);
    }



    return (
        <>

            <div className="tabsBox">
                <Tabs value={value} onChange={onHandleChange} className="tabs">
                    <div className="left-tabs">
                        <TabsList className='mb-3'>
                            <Tab style={value === 0 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={0}><i className='manageicon ti ti-lock-open' />  Change Password</Tab>
                            <Tab style={value === 1 ? { ...mangeTabActive } : {}} className='mange-tab2 nav-item' value={1}><i class="manageicon ti ti-lock-square" /> Login Username</Tab>
                        </TabsList>
                    </div>
                    <div className="TabPanelDiv">
                        <TabPanel value={0}>

                            <div className="card p-3">
                                <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                    {({ errors, touched, resetForm }) => {
                                        return <Form className="row">
                                            <div className="card-body">
                                                <div id="formValidationExamples" className="card row g-3 w-50 m-auto">

                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="ecommerce-product-name">Current Password</label>
                                                        <Field type='text'
                                                            name='currentPassword' placeholder='Current Password '
                                                            className={
                                                                `form-control
                                                            ${errors.currentPassword && touched.currentPassword ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="currentPassword" /></p>
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="ecommerce-product-name">New Password</label>
                                                        <Field type='text'
                                                            name='newPassword' placeholder='New Password '
                                                            className={
                                                                `form-control
                                                            ${errors.newPassword && touched.newPassword ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="newPassword" /></p>
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="ecommerce-product-name">Confirm Password</label>
                                                        <Field type='text'
                                                            name='confirmPassword' placeholder='Confirm Password '
                                                            className={
                                                                `form-control
                                                            ${errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : ''}`
                                                            }
                                                        />
                                                        <p className='error_message_p'> <ErrorMessage name="confirmPassword" /></p>
                                                    </div>

                                                    <div className="col-12 d-flex justify-content-end py-2">
                                                        <button type="button" className="btn btn-danger me-2" onClick={resetForm}>
                                                            <span className="ti-xs ti ti-x me-1"></span>Reset
                                                        </button>

                                                        <button
                                                            type="submit" name="submitButton" className="btn btn-primary">
                                                            <span class="ti-xs ti ti-key me-1"></span>Update
                                                        </button>

                                                    </div>

                                                </div>
                                            </div>
                                        </Form>
                                    }}
                                </Formik>
                            </div>

                        </TabPanel>

                        <TabPanel value={1}>
                            <div className="card p-3">
                                <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                    {({ errors, touched, resetForm }) => {
                                        return <Form className="row">
                                            <div className="card-body">
                                                <div id="formValidationExamples" className="card row g-3 w-50 m-auto">
                                                    <div>
                                                        <label className="form-label" htmlFor="formValidationSelect2">Username</label>
                                                        <div className="input-group">
                                                            <span className="input-group-text" id="basic-addon11"><i className=' ti ti-user'></i></span>
                                                            <Field type='text'
                                                                name='Username' placeholder='Username'
                                                                className={
                                                                    `form-control
                                                            ${errors.Username && touched.Username ? ' is-invalid' : ''}`
                                                                }
                                                            />
                                                        </div>
                                                        <p className='error_message_p'> <ErrorMessage name="Username" /></p>
                                                    </div>

                                                    <div className="col-12 d-flex justify-content-end py-2">
                                                        <button type="button" className="btn btn-danger me-2" onClick={resetForm}>
                                                            <span className="ti-xs ti ti-x me-1"></span>Reset
                                                        </button>

                                                        <button
                                                            type="submit" name="submitButton" className="btn btn-primary">
                                                            <span class="ti-xs ti ti-key me-1"></span>Update
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    }}
                                </Formik>
                            </div>

                        </TabPanel>
                    </div>
                </Tabs>
            </div>


        </>
    )
}

export default ResetPassword


