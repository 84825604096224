import React, { useState } from 'react';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import myCalendarEvent from '../../data/CalendarEvent';
import "react-big-calendar/lib/css/react-big-calendar.css";
import '../../styles/dashboardPageStyle.css'

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer


function Dasboard() {

    return (
        <>
            <div className="card">
                <div className="row mb-4 g-4 px-3">
                    <div className="col-sm-6">
                        <div className="card bg-gradient-primary text-white">
                            <div className="card-body">
                                <div className="bottomborder d-flex align-items-center justify-content-between">
                                    <div className="content-left">
                                        <span className="badge bg-label-primary rounded-circle p-2">
                                            <i className="ti ti-users-group ti-md" />
                                        </span>
                                        <br />
                                        <small>Employee</small>
                                    </div>
                                    <h4 className="mb-0 text-white">3</h4>
                                </div>
                                <div className='intervaldiv'>
                                    Total Strength
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div className="col-sm-6">
                        <div className="card bg-gradient-primary text-white">
                            <div className="card-body">
                                <div className="bottomborder d-flex align-items-center justify-content-between">
                                    <div className="content-left">
                                        <span className="badge bg-label-primary rounded-circle p-2">
                                            <i className="ti ti-school ti-md" />
                                        </span>
                                        <br />
                                        <small>Students</small>
                                    </div>
                                    <h4 className="mb-0 text-white">8</h4>
                                </div>
                                <div className='intervaldiv'>
                                    Total Strength
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card app-calendar-wrapper p-3">
                    <div style={{ height: 600 }}>
                        <Calendar
                            localizer={localizer}
                            events={myCalendarEvent}
                            startAccessor="start"
                            endAccessor="end"
                        />
                    </div>
                </div>

            </div>


        </>
    )
}
export default Dasboard