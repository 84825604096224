const BooksCategoryData = [
    {
        Sl: 1,
        Branch: 'Icon School & College',
        Name: 'Science',
        Action: '04',
    },
    							
    {
        Sl: 2,
        Branch: 'Icon School & College',
        Name: 'Science',
        Action: '06',
    },
    {
        Sl: 3,
        Branch: 'Icon School & College',
        Name: 'Science',
        Action: '06',
    }, 
    {
        Sl: 4,
        Branch: 'Icon School & College',
        Name: 'Science',
        Action: '06',
    },
    {
        Sl: 5,
        Branch: 'Icon School & College',
        Name: 'Science',
        Action: '06',
    },

];

export default BooksCategoryData;
