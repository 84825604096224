const LiveClassData = [
    {
        Sl: 1,
        Title: 'img1',
        MeetingID: '-',
        Date: '03.Feb.2024',
        StartTime: '09:00 AM',
        EndTime: '11:00 AM',
        CreatedBy: 'Nicholas Fletche',
        Status: '+1 979-776-5395',
        CreatedAt: '03.Feb.2024',
        Action: '+1 979-776-5395'

    },

    {
        Sl: 2,
        Title: 'img1',
        MeetingID: '-',
        Date: '03.Feb.2024',
        StartTime: '09:00 AM',
        EndTime: '11:00 AM',
        CreatedBy: 'Nicholas Fletche',
        Status: '+1 979-776-5395',
        CreatedAt: '03.Feb.2024',
        Action: '+1 979-776-5395'

    },
    {
        Sl: 3,
        Title: 'img1',
        MeetingID: '-',
        Date: '03.Feb.2024',
        StartTime: '09:00 AM',
        EndTime: '11:00 AM',
        CreatedBy: 'Nicholas Fletche',
        Status: '+1 979-776-5395',
        CreatedAt: '03.Feb.2024',
        Action: '+1 979-776-5395'

    },
    {
        Sl: 4,
        Title: 'img1',
        MeetingID: '-',
        Date: '03.Feb.2024',
        StartTime: '09:00 AM',
        EndTime: '11:00 AM',
        CreatedBy: 'Nicholas Fletche',
        Status: '+1 979-776-5395',
        CreatedAt: '03.Feb.2024',
        Action: '+1 979-776-5395'

    },
    {
        Sl: 5,
        Title: 'img1',
        MeetingID: '-',
        Date: '03.Feb.2024',
        StartTime: '09:00 AM',
        EndTime: '11:00 AM',
        CreatedBy: 'Nicholas Fletche',
        Status: '+1 979-776-5395',
        CreatedAt: '03.Feb.2024',
        Action: '+1 979-776-5395'

    },
    {
        Sl: 6,
        Title: 'img1',
        MeetingID: '-',
        Date: '03.Feb.2024',
        StartTime: '09:00 AM',
        EndTime: '11:00 AM',
        CreatedBy: 'Nicholas Fletche',
        Status: '+1 979-776-5395',
        CreatedAt: '03.Feb.2024',
        Action: '+1 979-776-5395'

    },
    {
        Sl: 7,
        Title: 'img1',
        MeetingID: '-',
        Date: '03.Feb.2024',
        StartTime: '09:00 AM',
        EndTime: '11:00 AM',
        CreatedBy: 'Nicholas Fletche',
        Status: '+1 979-776-5395',
        CreatedAt: '03.Feb.2024',
        Action: '+1 979-776-5395'

    },
    {
        Sl: 8,
        Title: 'img1',
        MeetingID: '-',
        Date: '03.Feb.2024',
        StartTime: '09:00 AM',
        EndTime: '11:00 AM',
        CreatedBy: 'Nicholas Fletche',
        Status: '+1 979-776-5395',
        CreatedAt: '03.Feb.2024',
        Action: '+1 979-776-5395'

    },
    {
        Sl: 9,
        Title: 'img1',
        MeetingID: '-',
        Date: '03.Feb.2024',
        StartTime: '09:00 AM',
        EndTime: '11:00 AM',
        CreatedBy: 'Nicholas Fletche',
        Status: '+1 979-776-5395',
        CreatedAt: '03.Feb.2024',
        Action: '+1 979-776-5395'

    }

];

export default LiveClassData;
