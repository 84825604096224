
import '../../styles/employeeListStyle.css'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import { Tab, TabPanel, Tabs, TabsList } from '@mui/base';
import { useRef, useState } from "react";
import { EmpAccountantData, EmpAdminData, EmpLibrarianData, EmpReceptionistData, EmpTeacherData } from '../../data/EmployeeListData';



const mangeTabActive = {
    color: '#7367f0',
    borderBottom: '2px solid #7367f0',
    // backgroundColor: '#7367f0',
    borderRadius: '0',
}

const EmployeeList = () => {

    const [value, setValue] = useState(0);

    const onHandleChange = (event, newValue) => {
        setValue(newValue)
    }


    return (
        <>

            <div className="EmployeeList-tabsBox">
                <Tabs value={value} onChange={onHandleChange} className="EmployeeList-tabs">
                    <div className="EmployeeList-left-tabs">
                        <TabsList className='mb-3'>
                            <Tab style={value === 0 ? { ...mangeTabActive } : {}} className='EmployeeList-tab2 nav-item' value={0}><i className='manageicon ti ti-user-circle' />Admin</Tab>
                            <Tab style={value === 1 ? { ...mangeTabActive } : {}} className='EmployeeList-tab2 nav-item' value={1}><i className="manageicon ti ti-user-circle" />Teacher</Tab>
                            <Tab style={value === 2 ? { ...mangeTabActive } : {}} className='EmployeeList-tab2 nav-item' value={2}><i className="manageicon ti ti-user-circle" />Accountant</Tab>
                            <Tab style={value === 3 ? { ...mangeTabActive } : {}} className='EmployeeList-tab2 nav-item' value={3}><i className="manageicon ti ti-user-circle" />Librarian</Tab>
                            <Tab style={value === 4 ? { ...mangeTabActive } : {}} className='EmployeeList-tab2 nav-item' value={4}><i className="manageicon ti ti-user-circle" />Receptionist</Tab>
                        </TabsList>
                    </div>
                    <div className="EmployeeList-TabPanelDiv">
                        <TabPanel value={0}>

                            <div class="card p-3">
                                <div className="exportFile">
                                    <div className="fileDownload">
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-copy" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-text" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-code-2" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-printer" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                                        </a>

                                    </div>

                                    <div>
                                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                                    </div>
                                </div>

                                <div className="card-body  px-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                                    <TableCell component={'th'} className=''>Photo</TableCell>
                                                    <TableCell component={'th'} className=''>Branch</TableCell>
                                                    <TableCell component={'th'} className=''>Staff Id</TableCell>
                                                    <TableCell component={'th'} className=''>Name</TableCell>
                                                    <TableCell component={'th'} className=''>Designation</TableCell>
                                                    <TableCell component={'th'} className=''>Department</TableCell>
                                                    <TableCell component={'th'} className=''>Email</TableCell>
                                                    <TableCell component={'th'} className=''>Mobile No</TableCell>
                                                    <TableCell component={'th'} className=''>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>

                                                {EmpAdminData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                                            <TableCell component={'td'}>
                                                                <ul class="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
                                                                    <li
                                                                        data-bs-toggle="tooltip"
                                                                        data-popup="tooltip-custom"
                                                                        data-bs-placement="top"
                                                                        class="avatar avatar-md pull-up"
                                                                        title="Lilian Fuller">
                                                                        <img src={item.Photo} alt="Avatar" class="rounded-circle" />
                                                                    </li>
                                                                </ul>
                                                            </TableCell>
                                                            <TableCell component={'td'}>{item.Branch}</TableCell>
                                                            <TableCell component={'td'}>{item.StaffId}</TableCell>
                                                            <TableCell component={'td'}>{item.Name}</TableCell>
                                                            <TableCell component={'td'}>{item.Designation}</TableCell>
                                                            <TableCell component={'td'}>{item.Department}</TableCell>
                                                            <TableCell component={'td'}>{item.Email}</TableCell>
                                                            <TableCell component={'td'}>{item.MobileNo}</TableCell>
                                                            <TableCell component={'td'}>{item.Action}</TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>

                        <TabPanel value={1}>
                        <div class="card p-3">
                                <div className="exportFile">
                                    <div className="fileDownload">
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-copy" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-text" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-code-2" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-printer" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                                        </a>

                                    </div>

                                    <div>
                                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                                    </div>
                                </div>

                                <div className="card-body  px-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                                    <TableCell component={'th'} className=''>Photo</TableCell>
                                                    <TableCell component={'th'} className=''>Branch</TableCell>
                                                    <TableCell component={'th'} className=''>Staff Id</TableCell>
                                                    <TableCell component={'th'} className=''>Name</TableCell>
                                                    <TableCell component={'th'} className=''>Designation</TableCell>
                                                    <TableCell component={'th'} className=''>Department</TableCell>
                                                    <TableCell component={'th'} className=''>Email</TableCell>
                                                    <TableCell component={'th'} className=''>Mobile No</TableCell>
                                                    <TableCell component={'th'} className=''>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>

                                                {EmpTeacherData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                                            <TableCell component={'td'}>
                                                                <ul class="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
                                                                    <li
                                                                        data-bs-toggle="tooltip"
                                                                        data-popup="tooltip-custom"
                                                                        data-bs-placement="top"
                                                                        class="avatar avatar-md pull-up"
                                                                        title="Lilian Fuller">
                                                                        <img src={item.Photo} alt="Avatar" class="rounded-circle" />
                                                                    </li>
                                                                </ul>
                                                            </TableCell>
                                                            <TableCell component={'td'}>{item.Branch}</TableCell>
                                                            <TableCell component={'td'}>{item.StaffId}</TableCell>
                                                            <TableCell component={'td'}>{item.Name}</TableCell>
                                                            <TableCell component={'td'}>{item.Designation}</TableCell>
                                                            <TableCell component={'td'}>{item.Department}</TableCell>
                                                            <TableCell component={'td'}>{item.Email}</TableCell>
                                                            <TableCell component={'td'}>{item.MobileNo}</TableCell>
                                                            <TableCell component={'td'}>{item.Action}</TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel value={2}>
                        <div class="card p-3">
                                <div className="exportFile">
                                    <div className="fileDownload">
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-copy" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-text" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-code-2" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-printer" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                                        </a>

                                    </div>

                                    <div>
                                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                                    </div>
                                </div>

                                <div className="card-body  px-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                                    <TableCell component={'th'} className=''>Photo</TableCell>
                                                    <TableCell component={'th'} className=''>Branch</TableCell>
                                                    <TableCell component={'th'} className=''>Staff Id</TableCell>
                                                    <TableCell component={'th'} className=''>Name</TableCell>
                                                    <TableCell component={'th'} className=''>Designation</TableCell>
                                                    <TableCell component={'th'} className=''>Department</TableCell>
                                                    <TableCell component={'th'} className=''>Email</TableCell>
                                                    <TableCell component={'th'} className=''>Mobile No</TableCell>
                                                    <TableCell component={'th'} className=''>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>

                                                {EmpAccountantData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                                            <TableCell component={'td'}>
                                                                <ul class="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
                                                                    <li
                                                                        data-bs-toggle="tooltip"
                                                                        data-popup="tooltip-custom"
                                                                        data-bs-placement="top"
                                                                        class="avatar avatar-md pull-up"
                                                                        title="Lilian Fuller">
                                                                        <img src={item.Photo} alt="Avatar" class="rounded-circle" />
                                                                    </li>
                                                                </ul>
                                                            </TableCell>
                                                            <TableCell component={'td'}>{item.Branch}</TableCell>
                                                            <TableCell component={'td'}>{item.StaffId}</TableCell>
                                                            <TableCell component={'td'}>{item.Name}</TableCell>
                                                            <TableCell component={'td'}>{item.Designation}</TableCell>
                                                            <TableCell component={'td'}>{item.Department}</TableCell>
                                                            <TableCell component={'td'}>{item.Email}</TableCell>
                                                            <TableCell component={'td'}>{item.MobileNo}</TableCell>
                                                            <TableCell component={'td'}>{item.Action}</TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel value={3}>
                        <div class="card p-3">
                                <div className="exportFile">
                                    <div className="fileDownload">
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-copy" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-text" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-code-2" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-printer" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                                        </a>

                                    </div>

                                    <div>
                                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                                    </div>
                                </div>

                                <div className="card-body  px-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                                    <TableCell component={'th'} className=''>Photo</TableCell>
                                                    <TableCell component={'th'} className=''>Branch</TableCell>
                                                    <TableCell component={'th'} className=''>Staff Id</TableCell>
                                                    <TableCell component={'th'} className=''>Name</TableCell>
                                                    <TableCell component={'th'} className=''>Designation</TableCell>
                                                    <TableCell component={'th'} className=''>Department</TableCell>
                                                    <TableCell component={'th'} className=''>Email</TableCell>
                                                    <TableCell component={'th'} className=''>Mobile No</TableCell>
                                                    <TableCell component={'th'} className=''>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>

                                                {EmpLibrarianData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                                            <TableCell component={'td'}>
                                                                <ul class="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
                                                                    <li
                                                                        data-bs-toggle="tooltip"
                                                                        data-popup="tooltip-custom"
                                                                        data-bs-placement="top"
                                                                        class="avatar avatar-md pull-up"
                                                                        title="Lilian Fuller">
                                                                        <img src={item.Photo} alt="Avatar" class="rounded-circle" />
                                                                    </li>
                                                                </ul>
                                                            </TableCell>
                                                            <TableCell component={'td'}>{item.Branch}</TableCell>
                                                            <TableCell component={'td'}>{item.StaffId}</TableCell>
                                                            <TableCell component={'td'}>{item.Name}</TableCell>
                                                            <TableCell component={'td'}>{item.Designation}</TableCell>
                                                            <TableCell component={'td'}>{item.Department}</TableCell>
                                                            <TableCell component={'td'}>{item.Email}</TableCell>
                                                            <TableCell component={'td'}>{item.MobileNo}</TableCell>
                                                            <TableCell component={'td'}>{item.Action}</TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                        <TabPanel value={4}>
                        <div class="card p-3">
                                <div className="exportFile">
                                    <div className="fileDownload">
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-copy" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-copy buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-file-excel" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-csv buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-text" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-pdf buttons-html5" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-file-code-2" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="ti ti-printer" /></span>
                                        </a>
                                        <a className="dt-button dropdown-item buttons-print" tabIndex={0} aria-controls="DataTables_Table_0" href="#">
                                            <span><i className="fs-6 fa-solid fa-table-columns" /></span>
                                        </a>

                                    </div>

                                    <div>
                                        <input type="search" className="form-control" placeholder="Search" aria-controls="DataTables_Table_0" />
                                    </div>
                                </div>

                                <div className="card-body  px-0">
                                    <div className="table-responsive text-nowrap">
                                        <Table className="table table-bordered">
                                            <TableHead component={'thead'} className='table-light'>
                                                <TableRow component={'tr'}>
                                                    <TableCell component={'th'} className=''>Sl</TableCell>
                                                    <TableCell component={'th'} className=''>Photo</TableCell>
                                                    <TableCell component={'th'} className=''>Branch</TableCell>
                                                    <TableCell component={'th'} className=''>Staff Id</TableCell>
                                                    <TableCell component={'th'} className=''>Name</TableCell>
                                                    <TableCell component={'th'} className=''>Designation</TableCell>
                                                    <TableCell component={'th'} className=''>Department</TableCell>
                                                    <TableCell component={'th'} className=''>Email</TableCell>
                                                    <TableCell component={'th'} className=''>Mobile No</TableCell>
                                                    <TableCell component={'th'} className=''>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody component={'tbody'}>

                                                {EmpReceptionistData.map((item) => (
                                                    <>
                                                        <TableRow component={'tr'}>
                                                            <TableCell component={'td'}>{item.Sl}</TableCell>
                                                            <TableCell component={'td'}>
                                                                <ul class="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
                                                                    <li
                                                                        data-bs-toggle="tooltip"
                                                                        data-popup="tooltip-custom"
                                                                        data-bs-placement="top"
                                                                        class="avatar avatar-md pull-up"
                                                                        title="Lilian Fuller">
                                                                        <img src={item.Photo} alt="Avatar" class="rounded-circle" />
                                                                    </li>
                                                                </ul>
                                                            </TableCell>
                                                            <TableCell component={'td'}>{item.Branch}</TableCell>
                                                            <TableCell component={'td'}>{item.StaffId}</TableCell>
                                                            <TableCell component={'td'}>{item.Name}</TableCell>
                                                            <TableCell component={'td'}>{item.Designation}</TableCell>
                                                            <TableCell component={'td'}>{item.Department}</TableCell>
                                                            <TableCell component={'td'}>{item.Email}</TableCell>
                                                            <TableCell component={'td'}>{item.MobileNo}</TableCell>
                                                            <TableCell component={'td'}>{item.Action}</TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </div>
                </Tabs>
            </div>


        </>
    )
}

export default EmployeeList

