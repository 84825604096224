import { Outlet } from "react-router-dom"
import Aside from "../../common/aside/Aside"
import Nav from "../../common/header/nav/Nav"

function AdminPage() {
    return (
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <Aside />
                    <div className="layout-page">
                        <Nav />
                        <div className="content-wrapper">
                            <div class="container-xxl flex-grow-1 container-p-y">
                                <Outlet></Outlet>
                            </div>


                            <footer className="content-footer footer bg-footer-theme">
                                <div className="container-xxl">
                                    <div className="footer-container d-flex align-items-center justify-content-between py-2 flex-md-row flex-column">
                                        <div>
                                            © 2024, made by <a href="" target="_blank" className="footer-link text-primary fw-medium">Abaris SoftTech</a>
                                        </div>
                                        <div className="d-none d-lg-inline-block">
                                            <a href="" className="footer-link me-4" target="_blank">License</a>
                                            <a href="" target="_blank" className="footer-link me-4">More</a>
                                            <a href="" target="_blank" className="footer-link me-4">Documentation</a>
                                            <a href="" target="_blank" className="footer-link d-none d-sm-inline-block">Support</a>
                                        </div>
                                    </div>
                                </div>
                            </footer>

                            <div class="content-backdrop fade"></div>

                        </div>

                    </div>
                </div>

                {/* Overlay */}
                <div className="layout-overlay layout-menu-toggle"></div>

                {/* Drag Target Area To SlideIn Menu On Small Screens */}
                <div className="drag-target"></div>

            </div>

        </>
    )
}
export default AdminPage