

import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material"
import ReportClassSectionData from '../../data/ReportClassSectionData'
import BooksCategoryData from "../../data/BooksCategoryData"


function BooksCategory() {

    const defalutValue = {
        category: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        category: yup.string().required('Category is Requird!'),


    })
    const handleSubmit = (value) => {
        console.log(value);

    }

    return (
        <>
            <div className=" card">
                <div className="app-ecommerce">
                    <div className="row">
                        {/* First column*/}
                        <div className="col-12 col-lg-4">
                            <div className="card mb-4">
                                <div className="card-header">
                                    <h5 className="card-title mb-0"><i class="manageicon fa-solid fa-edit" /> Add Books Category</h5>
                                </div>
                                <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                    {({ errors, touched, resetForm }) => {
                                        return <Form className="row">
                                            <div className="card-body">
                                                <div className="mb-3">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="ecommerce-product-discount-price"
                                                    >
                                                        Category
                                                    </label>
                                                    <Field type='text' name='category' placeholder='Enter Category '
                                                        className={
                                                            `form-control
                                                    ${errors.category && touched.category ? ' is-invalid' : ''}`
                                                        }
                                                    />
                                                    <p className='error_message_p'> <ErrorMessage name="category" /></p>
                                                </div>
                                                <div className="col-12 d-flex justify-content-end py-2">
                                                    <button type="button" className="btn btn-danger me-2" onClick={resetForm}>
                                                        <span className="ti-xs ti ti-x me-1"></span>Reset
                                                    </button>

                                                    <button
                                                        type="submit" name="submitButton" className="btn btn-primary">
                                                        <span class="ti-xs ti ti-plus me-1"></span>Save
                                                    </button>

                                                </div>
                                            </div>
                                        </Form>
                                    }}

                                </Formik>

                            </div>

                        </div>
                        <div className="card col-12 col-lg-8">
                            <div className="card-header">
                                <h5 className="card-title mb-0"><i className='manageicon fa-solid fa-list' /> Book Category List</h5>
                            </div>
                            <div className="card-body px-0">
                                <div className="table-responsive text-nowrap">
                                    <Table className="table table-bordered">
                                        <TableHead component={'thead'} className='table-light'>
                                            <TableRow component={'tr'}>
                                                <TableCell component={'th'} className=''>Sl</TableCell>
                                                <TableCell component={'th'} className=''>Branch</TableCell>
                                                <TableCell component={'th'} className=''>Name</TableCell>
                                                <TableCell component={'th'} className=''>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody component={'tbody'}>

                                            {BooksCategoryData.map((item) => (
                                                <>
                                                    <TableRow component={'tr'}>
                                                        <TableCell component={'td'}>{item.Sl}</TableCell>
                                                        <TableCell component={'td'}>{item.Branch}</TableCell>
                                                        <TableCell component={'td'}>{item.Name}</TableCell>
                                                        <TableCell component={'td'}>
                                                            <div className=' d-flex align-items-center'>
                                                                <button type="button" class="btn rounded-pill btn-icon btn-outline-dark waves-effect me-1 btn-sm">
                                                                    <span class="fa-solid fa-pen-nib fa-sm"></span>
                                                                </button>
                                                                <button type="button" class="btn rounded-pill btn-icon btn-danger waves-effect waves-light btn-sm">
                                                                    <span class="ti ti-trash ti-xs"></span>
                                                                </button>
                                                            </div>
                                                        </TableCell>

                                                    </TableRow>
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
export default BooksCategory