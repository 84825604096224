
import img5 from '../assets/images/avatars/2.png'

export const BooksData = [
    {
        Sl: 1,
        BookTitle: 'Mathematic',
        Cover: img5,
        Edition: '6',
        ISBNNo: '01.Jan.2024',
        Category: '31.Dec.2024',
        Description: '312024',
        PurchaseDate: '00:05:00',
        Price: '$10',
        TotalStock: 'cdfff',
        IssuedCopies: 'ff'
    },
    {
        Sl: 2,
        
        BookTitle: 'Mathematic',
        Cover: img5,
        Edition: '6',
        ISBNNo: '01.Jan.2024',
        Category: '31.Dec.2024',
        Description: '312024',
        PurchaseDate: '00:05:00',
        Price: '$10',
        TotalStock: 'cdfff',
        IssuedCopies: 'ff'
    }

];