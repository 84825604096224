import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logoImg from '../../assets/images/mainlogo.png';



function Aside() {
    const [allAside, setAllAside] = useState({
        admin: false,
        studentDetails: false,
        employee: false,
        humanResource: false,
        leave: false,
        library: false,
        reports: false,
        studentReports: false,

    });

    const changeOpen = (val, str, argu,last) => {
        if (argu) {
            const clone = { ...allAside, [str]: val, [last]: allAside[last] };
            setAllAside(clone);
            return
        }
        const clone = { ...allAside };
        for (const iterator in clone) {
            if (iterator == str) {
                clone[str] = val;
            } else {
                clone[iterator] = false;
            }
        }
        setAllAside(clone);
    };

    return (
        <>
            <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme" data-bg-className="bg-menu-theme" >
                <div className="app-brand demo d-flex justify-content-between align-items-center">
                    <Link to="/admin" className="app-brand-link">
                        <img width={150} src={logoImg} alt="img" />
                    </Link>
                    <a href="" className="layout-menu-toggle menu-link text-large">
                        <a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto">
                            <i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle" />
                            <i className="ti ti-x d-block d-xl-none ti-sm align-middle" />
                        </a>
                    </a>
                </div>
                <div className="menu-inner-shadow" style={{ display: 'none' }} />
                <ul className="menu-inner py-1 ps ps--active-y " id="scroll">
                    {/* Charts & Maps */}
                    <li className="menu-header small text-uppercase">
                        <span className="menu-header-text" data-i18n="Main">Main</span>
                    </li>

                    <li className="menu-item">
                        <Link to="/admin" className="menu-link">
                            <i className="menu-icon tf-icons ti ti-layout-grid" />
                            <div data-i18n="Dashboards">Dashboards</div>
                        </Link>
                    </li>

                

                    <li className={`menu-item ${allAside.studentDetails ? "open" : ""}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => {
                            changeOpen(!allAside.studentDetails, "studentDetails");
                        }}>
                            <i className="menu-icon tf-icons ti ti-school" />
                            <div data-i18n="Student Details">Student Details</div>
                            {/* <div className="badge bg-primary rounded-pill ms-auto">5</div> */}
                        </Link >
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <Link to="student_list" className="menu-link">
                                    <div data-i18n="Student List">Student List</div>
                                </Link>
                            </li>

                        </ul>
                    </li>

                    <li className={`menu-item ${allAside.employee ? "open" : ""}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => {
                            changeOpen(!allAside.employee, "employee");
                        }}>
                            <i className="menu-icon tf-icons ti ti-users-group" />
                            <div data-i18n="Employee">Employee</div>
                            {/* <div className="badge bg-primary rounded-pill ms-auto">5</div> */}
                        </Link >
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <Link to="employee_list" className="menu-link">
                                    <div data-i18n="Employee List">Employee List</div>
                                </Link>
                            </li>

                        </ul>
                    </li>

                    <li className={`menu-item ${allAside.humanResource ? "open" : ""}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => {
                            changeOpen(!allAside.humanResource, "humanResource");
                        }}>
                            <i className="menu-icon tf-icons ti ti-rotate-rectangle" />
                            <div data-i18n="Human Resource">Human Resource</div>
                            {/* <div className="badge bg-primary rounded-pill ms-auto">5</div> */}
                        </Link >
                        <ul className="menu-sub">
                            <li className={`menu-item ${allAside.leave ? "open" : ""}`}>
                                <Link to="#" className="menu-link menu-toggle" onClick={() => {
                                    changeOpen(!allAside.leave, "leave", 'megaMenu' ,'humanResource');
                                }}>
                                    <i className="menu-icon tf-icons ti ti-smart-home" />
                                    <div data-i18n="Leave">Leave</div>
                                    {/* <div className="badge bg-primary rounded-pill ms-auto">5</div> */}
                                </Link >
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <Link to="my_application" className="menu-link">
                                            <div data-i18n="My Application ">My Application </div>
                                        </Link>
                                    </li>

                                </ul>
                            </li>

                        </ul>
                    </li>

                    <li className={`menu-item ${allAside.library ? "open" : ""}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => {
                            changeOpen(!allAside.library, "library");
                        }}>
                            <i className="menu-icon tf-icons ti ti-vocabulary" />
                            <div data-i18n="Library">Library</div>
                            {/* <div className="badge bg-primary rounded-pill ms-auto">5</div> */}
                        </Link >
                        <ul className="menu-sub">
                            <li className="menu-item">
                                <Link to="books" className="menu-link">
                                    <div data-i18n="Books">Books</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="books_category" className="menu-link">
                                    <div data-i18n="Books Category">Books Category</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="my_issued_book" className="menu-link">
                                    <div data-i18n="My Issued Book">My Issued Book</div>
                                </Link>
                            </li>
                            <li className="menu-item">
                                <Link to="book_issue_return" className="menu-link">
                                    <div data-i18n="Book Issue / Return ">Book Issue / Return </div>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li className="menu-item">
                        <Link to="message" className="menu-link">
                            <i className="menu-icon tf-icons ti ti-mail-opened" />
                            <div data-i18n="Message">Message</div>
                        </Link>
                    </li>

                    <li className={`menu-item ${allAside.reports ? "open" : ""}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => {
                            changeOpen(!allAside.reports, "reports");
                        }}>
                            <i className="menu-icon tf-icons ti ti-chart-pie" />
                            <div data-i18n="Reports">Reports</div>
                            {/* <div className="badge bg-primary rounded-pill ms-auto">5</div> */}
                        </Link >
                        <ul className="menu-sub">
                            <li className={`menu-item ${allAside.studentReports ? "open" : ""}`}>
                                <Link to="#" className="menu-link menu-toggle" onClick={() => {
                                    changeOpen(!allAside.studentReports, "studentReports", 'megaMenu' ,'reports');
                                }}>
                                    <i className="menu-icon tf-icons ti ti-smart-home" />
                                    <div data-i18n="Student Reports">Student Reports</div>
                                    {/* <div className="badge bg-primary rounded-pill ms-auto">5</div> */}
                                </Link >
                                <ul className="menu-sub">
                                    <li className="menu-item">
                                        <Link to="login_credential" className="menu-link">
                                            <div data-i18n="Login Credential ">Login Credential </div>
                                        </Link>
                                    </li>
                                    <li className="menu-item">
                                        <Link to="admission_report" className="menu-link">
                                            <div data-i18n="Admission Report ">Admission Report </div>
                                        </Link>
                                    </li>

                                    <li className="menu-item">
                                        <Link to="class_section_report" className="menu-link">
                                            <div data-i18n="Class & Section Report ">Class & Section Report </div>
                                        </Link>
                                    </li>

                                </ul>
                            </li>

                        </ul>
                    </li>


                    <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                        <div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }} />
                    </div>
                    <div className="ps__rail-y" style={{ top: 0, height: 384, right: 4 }}>
                        <div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 74 }} />
                    </div>
                </ul>
            </aside>

        </>
    )
}
export default Aside