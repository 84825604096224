
const MyApplicationData = [
    {
        id: 1,
        Applicant: 'gggg',
        LeaveCategory: 'B Recusandae',
        DateOfStart: '01.Jul.2023',
        DateOfEnd: '21.Jul.2023',
        Days: 'Recusandae Est obca',
        ApplyDate: 'Recusandae',
        Status: ' Est obca',
        Action:'ddd'
    },
    
    {
        id: 2,
        Applicant: 'gggg',
        LeaveCategory: 'B Recusandae',
        DateOfStart: '01.Jul.2023',
        DateOfEnd: '21.Jul.2023',
        Days: 'Recusandae Est obca',
        ApplyDate: 'Recusandae',
        Status: ' Est obca',
        Action:'ddd'
    },
    {
        id: 3,
        Applicant: 'gggg',
        LeaveCategory: 'B Recusandae',
        DateOfStart: '01.Jul.2023',
        DateOfEnd: '21.Jul.2023',
        Days: 'Recusandae Est obca',
        ApplyDate: 'Recusandae',
        Status: ' Est obca',
        Action:'ddd'
    }

];

export default MyApplicationData;
