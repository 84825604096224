import { Button } from "@mui/material";
const feesDataRows = [
    {
        id: 1,
        FeesType: 'January Month Fees',
        DueDate: '02.Jan.2024',
        Status: <Button className=" text-capitalize p-0" variant="outlined">unpaid</Button>,
        Amount: '$100.00',
        Discount: '$0.00',
        Fine: '$0.00',
        paid:'$0.00',
        Balance: '$100.00'
    },
    {
        id: 2,
        FeesType: 'January Month Fees',
        DueDate: '02.Jan.2024',
        Status: <Button className=" text-capitalize p-0" variant="outlined">unpaid</Button>,
        Amount: '$40.00',
        Discount: '$0.00',
        Fine: '$0.00',
        paid:'$0.00',
        Balance: '$100.00'
    },
    {
        id: 3,
        FeesType: 'January Month Fees',
        DueDate: '02.Jan.2024',
        Status: <Button className=" text-capitalize p-0" variant="outlined">paid</Button>,
        Amount: '$50.00',
        Discount: '$0.00',
        Fine: '$0.00',
        paid:'$300.00',
        Balance: '$100.00'
    },
    {
        id: 4,
        FeesType: 'January Month Fees',
        DueDate: '02.Jan.2024',
        Status: <Button className=" text-capitalize p-0" variant="outlined">unpaid</Button>,
        Amount: '$60.00',
        Discount: '$0.00',
        Fine: '$0.00',
        paid:'$0.00',
        Balance: '$100.00'
    },
    {
        id: 5,
        FeesType: 'January Month Fees',
        DueDate: '02.Jan.2024',
        Status: <Button className=" text-capitalize p-0" variant="outlined">unpaid</Button>,
        Amount: '$10.00',
        Discount: '$0.00',
        Fine: '$0.00',
        paid:'$0.00',
        Balance: '$10.00'
    },
    {
        id: 6,
        FeesType: 'January Month Fees',
        DueDate: '02.Jan.2024',
        Status: <Button className=" text-capitalize p-0" variant="outlined">unpaid</Button>,
        Amount: '$100.00',
        Discount: '$0.00',
        Fine: '$0.00',
        paid:'$0.00',
        Balance: '$100.00'
    },
    {
        id: 7,
        FeesType: 'January Month Fees',
        DueDate: '02.Jan.2024',
        Status: <Button className=" text-capitalize p-0" variant="outlined">unpaid</Button>,
        Amount: '$100.00',
        Discount: '$0.00',
        Fine: '$0.00',
        paid:'$0.00',
        Balance: '$100.00'
    },
    {
        id: 8,
        FeesType: 'January Month Fees',
        DueDate: '02.Jan.2024',
        Status: <Button className=" text-capitalize p-0" variant="outlined">unpaid</Button>,
        Amount: '$100.00',
        Discount: '$0.00',
        Fine: '$0.00',
        paid:'$0.00',
        Balance: '$100.00'
    },
    {
        id: 9,
        FeesType: 'January Month Fees',
        DueDate: '02.Jan.2024',
        Status: <Button className=" text-capitalize p-0" variant="outlined">unpaid</Button>,
        Amount: '$100.00',
        Discount: '$0.00',
        Fine: '$0.00',
        paid:'$0.00',
        Balance: '$100.00'
    }

];

export default feesDataRows;
